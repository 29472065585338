import { Component } from '@angular/core';

import { NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar, Style } from '@capacitor/status-bar';
import { GlobalSettingsService } from './services/global-settings.service';
import { ThemeDetection, ThemeDetectionResponse } from '@ionic-native/theme-detection/ngx';
import { DarkmodeService } from './services/darkmode.service';
import { TimeService } from './services/time.service';
import { HttpFunctionResult, HttpService } from './services/http.service';
import { StatusService } from './services/status.service';
import { OfflineModeService } from './services/offline-mode.service';
import { register } from 'swiper/element/bundle';
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private settings: GlobalSettingsService,
    private navCtrl: NavController,
    private themeDet: ThemeDetection,
    private timeSrv: TimeService,
    public darkmode: DarkmodeService,
    private statusSrv: StatusService,
    private http: HttpService,
    private offlineModeSrv: OfflineModeService
  ) {
    this.initializeApp();
    this.settings.terminalMode.subscribe((result=>{
      if(result !== null && this.platform.is("capacitor") && this.platform.is("android")){
        if(result === true){
          StatusBar.hide()
        }else{
          StatusBar.show()
        }
      }
    }))
  } 

  initializeApp() {
    this.platform.ready().then(async () => {
      this.settings.loadFonts()
      this.settings.loadShowImageTerminal()
      this.settings.loadTouchOnEmployee()
      this.settings.loadShowUserList()
      this.settings.loadCrazyMode()
      if(this.platform.is("capacitor")){
        StatusBar.setStyle({style: Style.Dark})
        if(this.platform.is("capacitor") && this.platform.is("android")){
          StatusBar.setBackgroundColor({color: "#3880ff"})
          StatusBar.setOverlaysWebView({overlay: false})
        }
        this.splashScreen.hide();
      }
      this.timeSrv.startTimer()
      await Promise.all([ 
        this.detectTheme(), 
        this.checkTerminalMode(),
        this.statusSrv.loadStates(),
        this.settings.loadShowStatiColor(),
        this.settings.loadAutoStamp(),
        this.settings.loadSentryEnabled(),
        this.getServerInformations(),
        this.offlineModeSrv.loadOfflineModeSettings()
      ])
    });
  }

  private async checkTerminalMode(){
    if(await this.settings.loadTerminalMode()) this.navCtrl.navigateRoot("tabs/terminal-status")
  }
   
  private async detectTheme(){
    let isStorageDarkmode = await this.settings.loadDarkmode()
    if(isStorageDarkmode == null){
      if(this.platform.is("capacitor")){
        this.themeDet.isAvailable().then((res: ThemeDetectionResponse) => {
          if(res.value) { 
            this.themeDet.isDarkModeEnabled().then((res: ThemeDetectionResponse) => {
              this.darkmode.setIsDarkmode(res.value)
            })
            .catch((error: any) => {
              this.darkmode.setIsDarkmode(false)
            });
          }
       })
        .catch((error: any) => { 
          this.darkmode.setIsDarkmode(false)
        })
       }else{
         this.darkmode.setIsDarkmode(window.matchMedia("(prefers-color-scheme: dark)").matches)
       }
    }else{
      this.darkmode.setIsDarkmode(isStorageDarkmode)
    }
  }

  private async getServerInformations(){
    const result: HttpFunctionResult<any> = await this.http.GET({path: "", handleError: false})
    if(result.success) this.http.serverInformations = result.data
  }



}