import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DarkmodeService } from './darkmode.service';
import { StorageManagerService } from './storage-manager.service';
import { FeedbackService } from './feedback.service';
import { ErrorReportConfirmPage } from '../pages/error-report-confirm/error-report-confirm.page';
import * as Sentry from "@sentry/angular"
import packageInfo from "../../../package.json"
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalSettingsService{

  private _terminalMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  private _ubersichtSchrift: BehaviorSubject<number> = new BehaviorSubject<number>(null)
  private _timeFontSize: BehaviorSubject<number> = new BehaviorSubject<number>(null)
  private _imageFontSize: BehaviorSubject<number> = new BehaviorSubject<number>(null)
  private _imageShowTerminal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null)
  private _touchOnEmployee: boolean
  private _showUserList: boolean
  private _autoStamp: boolean
  private _isShowStatiColor: boolean = true
  private _sentryEnabled: boolean = false
  public crazyMode: boolean = false

  constructor(
    private storageManager: StorageManagerService, 
    private darkmode: DarkmodeService,
    private feedback: FeedbackService
    ) {
      
    }

  public get terminalMode(): Observable<boolean>{
    return this._terminalMode.asObservable()
  }

  public get uebersichtSchrift(): Observable<number>{
    return this._ubersichtSchrift.asObservable()
  }

  public get uebersichtSchriftValue(): number{
    return this._ubersichtSchrift.value
  }

  public get timeFontSize(): Observable<number>{
    return this._timeFontSize.asObservable()
  }

  public get timeFontSizeValue(): number{
    return this._timeFontSize.value
  }

  public get imageFontSize(): Observable<number>{
    return this._imageFontSize.asObservable()
  }
  
  public get imageFontSizeValue(): number{
    return this._imageFontSize.value
  }

  public get isShowImageTerminal(): Observable<boolean>{
    return this._imageShowTerminal.asObservable()
  }

  public get isShowImageTerminalValue(): boolean{
    return this._imageShowTerminal.value
  }

  public get touchOnEmployee(): boolean{
    return this._touchOnEmployee
  }

  public get isShowStatiColor(): boolean{
    return this._isShowStatiColor
  }

  public get showUserList(): boolean{
    return this._showUserList
  }

  public get autoStamp(): boolean{
    return this._autoStamp
  }

  public get sentryEnabled(): boolean{
    return this._sentryEnabled
  }

  //LADEN
  public async getStatus(){
    return await this.storageManager.getItem<number>("status_font_size", 25)
  }

  public async loadFonts(){
      this._ubersichtSchrift.next(await this.storageManager.getItem<number>("uebersicht_font_size", 18))
      this._timeFontSize.next(await this.storageManager.getItem<number>("time_font_size", 50))
      this._imageFontSize.next(await this.storageManager.getItem<number>("image_font_size", 30))
  }

  public async loadTerminalMode(){
    this._terminalMode.next(await this.storageManager.getItem<boolean>("terminalmode", false))
    return this._terminalMode.value
  }

  public async loadShowImageTerminal(){
    this._imageShowTerminal.next(await this.storageManager.getItem<boolean>("show_image_terminal", true))
  }

  public async loadTouchOnEmployee(){
    this._touchOnEmployee = await this.storageManager.getItem<boolean>("touchOnEmployee", true)
  }

  public async loadShowUserList(){
    this._showUserList = await this.storageManager.getItem<boolean>("showUserList", true)
  }

  public async loadCrazyMode(){
    this.crazyMode = await this.storageManager.getItem<boolean>("crazyMode", false)
  }

  public async loadDarkmode(){
    let isDarkmode = await this.storageManager.getItem<boolean>("darkmode", null)
    return isDarkmode  
  }
   
  public async loadShowStatiColor(){
    this._isShowStatiColor = await this.storageManager.getItem<boolean>("isShowStatiColor", true)
  }

  public async loadAutoStamp(){
    this._autoStamp = await this.storageManager.getItem<boolean>("autoStamp", true)
  }

  public async loadSentryEnabled(){
    this._sentryEnabled = await this.storageManager.getItem<boolean>("sentryEnabled", false)
    if(this._sentryEnabled) await this.initSentry()
  }

  //SPEICHERN
  public async setTerminalMode(mode: boolean){
    this._terminalMode.next(mode)
    await this.storageManager.setItem<boolean>("terminalmode", mode)
  }

  public async setTouchOnEmployee(mode: boolean){
    this._touchOnEmployee = mode
    await this.storageManager.setItem<boolean>("touchOnEmployee", mode)
  }

  public async setShowUserList(mode: boolean){
    this._showUserList = mode
    await this.storageManager.setItem<boolean>("showUserList", mode)
  }

  public async setAutoStamp(mode: boolean){
    this._autoStamp = mode
    await this.storageManager.setItem<boolean>("autoStamp", mode)
  }

  public async saveSchrift(uebersicht: number, status: number, time: number, bild: number){
    await this.storageManager.setItem<number>("uebersicht_font_size", uebersicht)
    await this.storageManager.setItem<number>("status_font_size", status)
    await this.storageManager.setItem<number>("time_font_size", time)
    await this.storageManager.setItem<number>('image_font_size', bild)
    this._ubersichtSchrift.next(uebersicht)
    this._timeFontSize.next(time)
    this._imageFontSize.next(bild)
  } 

  public async saveDarkmode(isDarkmode: boolean){
    await this.storageManager.setItem<boolean>("darkmode", isDarkmode)
    this.darkmode.setIsDarkmode(isDarkmode)
  }

  public async saveShowStatiColor(isShowStatiColor: boolean){    
    await this.storageManager.setItem<boolean>("isShowStatiColor", isShowStatiColor)
    this._isShowStatiColor = isShowStatiColor  
  }


  public async setShowTerminaImage(value: boolean){
    await this.storageManager.setItem<boolean>("show_image_terminal", value)
    this._imageShowTerminal.next(value)
  }

  public async setEntryEnabled(value: boolean){
    await this.storageManager.setItem<boolean>("sentryEnabled", value)
    this._sentryEnabled = value
    if(this._sentryEnabled) await this.initSentry()
  }

  public async switchCrazyMode(){
    await this.storageManager.setItem<boolean>("crazyMode", !this.crazyMode)
    this.crazyMode = !this.crazyMode
    if(this.crazyMode) alert("An 😏")
    else alert("Aus 🤓")
  } 

  public  async requestBugReport(){
    await this.feedback.openModal(ErrorReportConfirmPage)
  }

  public async initSentry(){
    if(!this._sentryEnabled) return
    console.log("Sentry enabled");
    
    Sentry.init({
      enabled: true,
      dsn: environment.sentryUrl,
      release: `zeiterfassung-app-${packageInfo.version}`,
      environment: environment.production ? "production" : "development",
      tracesSampleRate: 1.0,
    })
  }

}
