import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { catchError, Observable, of, retry, take, timeout } from 'rxjs';
import { Role } from './auth.service';
import { FeedbackService } from './feedback.service';
import { StorageManagerService } from './storage-manager.service';
const jwt = new JwtHelperService();
import * as semver from "semver"
import { AFSGetOptions, AFSPostOptions, AFSPutOptions } from '../interfaces/AFSHttpOptions';

export interface HttpFunctionResult<T>{
  success: boolean
  message?: string
  data?: T
}

export interface DecodedJWT{
  username?: string,
  id?: number
  sub?: number,
  firstName?: string
  lastName?: string
  roles?: Role[]
}

export interface ServerInformations{
  server_version?: string,
  server_version_code?: string
}

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private baseUrl: string = ""
  private _token: string = ""
  private _refreshToken: string = ''
  public serverVersion: string = "1.2"

  public _serverInformations: ServerInformations = {}
  private _localServerVersion: ServerInformations = {}


  public set serverInformations(value: ServerInformations){
    this._serverInformations = value 
    this.storageManager.setItem<ServerInformations>("SERVER_INFORMATIONS", value)
    this._localServerVersion = value
    this.serverVersion = value?.server_version?.substring(0, 3)
  }

  public get serverInformations(): ServerInformations{
    if(this._serverInformations?.server_version)
      return this._serverInformations
    else return this._localServerVersion
  }

  //#region Constructor
  constructor(
    private http: HttpClient,
    private feedback: FeedbackService,
    private storageManager: StorageManagerService,
  ) { 
    this.loadStorage()
  }

  public get token(): string{
    return this._token
  }
  public set token(token: string){
    this._token = token
  }
  public set refreshToken(token: string){
    this._refreshToken = token
  }
  public get refreshToken(): string{
    return this._refreshToken
  }

  public set defaultURL(url: string){
    this.baseUrl = url
  }

  public get getbaseUrl(): string{
    return this.baseUrl
  }

  public get decodedJWT(): DecodedJWT{
    return jwt.decodeToken(this._token) as DecodedJWT
  }

  public roleIncludes(role: Role): boolean{
    return this.decodedJWT?.roles?.includes(role)
  }

  public serverVersionGreaterThan(checkVersion: string, fromLocalVersion: boolean = false): boolean{
    if(!this.serverInformations?.server_version) return false
    if(this.serverInformations?.server_version == checkVersion) return true
    return semver.lt(checkVersion, this.serverInformations?.server_version)
  }


  public async loadStorage(){
    this.baseUrl  = await this.storageManager.getItem<string>("BASEURL", "")
    this._token = await this.storageManager.getItem<string>("ACCESS_TOKEN", "")
    this._refreshToken = await this.storageManager.getItem<string>("REFRESH_TOKEN", "")
    this._localServerVersion = await this.storageManager.getItem<ServerInformations>("SERVER_INFORMATIONS", {})
  }

  /**
   * Sendet eine HTTP POST-Anfrage an die angegebene URL mit den angegebenen Daten und gibt eine Promise zurück, die ein HttpFunctionResult-Objekt enthält.
   * @template T - Der Typ der Daten, die an den Server gesendet werden.
   * @template K - Der Typ der erwarteten Antwortdaten.
   * @param options - Ein Objekt, das die Optionen für die POST-Anfrage enthält.
   * @returns Eine Promise, die ein HttpFunctionResult-Objekt enthält.
   */
  public async POST<T, K>(options: AFSPostOptions<T, K>): Promise<HttpFunctionResult<K>>{
    if(options.handleError == undefined) options.handleError = true
    if(options.withAuth == undefined) options.withAuth = true
    return new Promise<HttpFunctionResult<K>>(resolve=>{
      let header: HttpHeaders = new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": options.withAuth ? `Bearer ${this._token}` : "",
        "x-version": this.serverVersion
      })
      
      this.http.post<{data: K}>(`${this.baseUrl}/api/${options.path}`, options.body as T, {headers: header, observe: "response"})
        .pipe(
          timeout(options?.timeout | 20000),
          retry(0),
          take(1),
          catchError(async (err: HttpErrorResponse)=> {
            const error: HttpFunctionResult<K> = options?.handleError ? await this.handleError(err) : {success: false, message: ""}
            if(err.error?.data) error.data = err.error.data
            if(options.statusResult) options.statusResult(err.status, error, err)
            return resolve(error)
          })
        )
        .subscribe((response: HttpResponse<{data: K}>)=>{
          if(!response) return
          if(options.statusResult) options.statusResult(response?.status, {success: true, data: response.body as K})
          resolve({success: true, data: response.body as K})
        })
    })
  }


    /**
   * Führt eine HTTP GET-Anfrage aus.
   * @template T - Der Typ der zurückgegebenen Daten.
   * @param options - Die Optionen für die GET-Anfrage.
   * @returns Ein Promise, das ein HttpFunctionResult-Objekt mit den Daten der Anfrage zurückgibt.
   */
  public async GET<T>( options: AFSGetOptions<T>): Promise<HttpFunctionResult<T>>{
    if(options.handleError == undefined) options.handleError = true
    return new Promise<HttpFunctionResult<T>>(resolve=>{
      let header: HttpHeaders = new HttpHeaders({
        "Authorization": `Bearer ${this._token}`,
        "x-version": this.serverVersion
      })
      this.http.get<{data: T}>(`${this.baseUrl}/api/${options.path}`, {headers: header, observe: "response"})
      .pipe(
        timeout(options?.timeout | 20000),
        retry(0),
        catchError(async (err: HttpErrorResponse)=> 
          resolve(options?.handleError ? await this.handleError(err) : {success: false, message: ""}))
      )
      .subscribe((response: HttpResponse<any>)=>{
        resolve({success: true, data: response?.body as T})
      })
    })
  }

    /**
   * Führt eine HTTP PUT-Anfrage aus.
   * @param options - Die Optionen für die PUT-Anfrage.
   * @returns Eine Promise, die ein HttpFunctionResult-Objekt enthält.
   * @template T - Der Typ des Anfragekörpers.
   * @template K - Der Typ der Antwortdaten.
   */
  public async PUT<T, K>(options: AFSPutOptions<T, K>): Promise<HttpFunctionResult<K>>{
    if(options.handleError == undefined) options.handleError = true
    return new Promise<HttpFunctionResult<K>>(resolve=>{
      let header: HttpHeaders = new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization":`Bearer ${this._token}`,
        "x-version": this.serverVersion
      })

      this.http.put<{data: K}>(`${this.baseUrl}/api/${options.path}`, options.body as T, {headers: header, observe: "response"})
      .pipe(
        timeout(options?.timeout | 20000),
        take(1),
        catchError(async (err: HttpErrorResponse)=> {
          const error: HttpFunctionResult<K> = options?.handleError ? await this.handleError(err) : {success: false, message: ""}
          if(err.error?.data) error.data = err.error.data
          if(options.statusResult) options.statusResult(err.status, error, err)
          return resolve(error)
        })
      )
      .subscribe((response: HttpResponse<{data: K}>)=>{
        if(!response) return
        if(options.statusResult) options.statusResult(response?.status, {success: true, data: response.body as K})
        resolve({success: true, data: response.body as K})
      })
    })
  }

  public getNewAccessToken(): Observable<any>{
    if(!this._refreshToken) return of(null)
    let header: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": `Bearer ${this._refreshToken}`,
      "x-version": this.serverVersion
    })
    
    return this.http.post(`${this.baseUrl}/api/auth/renew`, {}, {headers: header, observe: "response"})
  }

  public getExtentionToken(): Observable<any>{
    if(!this._refreshToken) return of(null)
    let header: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": `Bearer ${this._refreshToken}`,
      "x-version": this.serverVersion
    })
    
    return this.http.post(`${this.baseUrl}/api/auth/watch`, {}, {headers: header, observe: "response"})
  }

  //#endregion

  //#region Error Handler

  public async handleError(error: HttpErrorResponse, handleNotFindAsOldVersion: boolean = false): Promise<HttpFunctionResult<any>>{
    if(error.status == 401) return null
    let result: HttpFunctionResult<any>

    if(handleNotFindAsOldVersion && (error.status == 404 || error.status == 405)) result = {success: false, message: "Das Feature ist leider nicht mit der Server-Version kompatibel. Bitte updaten Sie erst den Server."}
    else result = (error.status === 404 || error.status === 400 || error.status === 403 || error.status === 409 || error.status === 500 ?{
      success: false,
      message: error?.error?.message ? error.error.message : typeof error.error == "string" ? error.error : "No Message - Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal."
    } :{
      success: false,
      message: "Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal."
    })

    await this.feedback.showError(result.message, 5500)
    return result
  } 

  
  //#endregion

}
